import AppShell from "../../components/AppShell";
import { CampaignCard } from "../Campaigns/components/CampaignCard";
import { useQuery } from "@tanstack/react-query";
import { useFetch } from "../../context/FetchContext";
import { HomePageHeader } from "./components/HomePageHeader";
import styles from "./HomePage.module.css";
const HomePage = () => {
  const { apiClient } = useFetch();
  const { data: campaigns } = useQuery({
    queryKey: ["campaigns"],
    queryFn: async () => {
      const res = await apiClient.api.v1.campaigns.$get();

      return await res.json();
    },
  });

  return (
    <AppShell header={<HomePageHeader />}>
      <main className={styles.main}>
        <h4>FREE Demo Campaign</h4>
        {campaigns?.map((campaign) => (
          <CampaignCard
            id={campaign.id}
            name={campaign.name}
            key={campaign.id}
            image={campaign.images?.[1]?.url}
          />
        ))}
      </main>
    </AppShell>
  );
};

export default HomePage;
