import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { publicFetch } from "../../../utils/fetch";
import StandardButton from "../../../components/StandardButton";
import TextInput from "../../../components/TextInput";
import LeftArrowIcon from "../../../icons/LeftArrowIcon";
import IconButton from "../../../components/IconButton";
import styles from "./RequestPasswordReset.module.css";
import ForgetPasswordEmailVerification from "../../../components/ForgetPasswordEmailVerification/ForgetPasswordEmailVerification";
export const RequestPasswordReset = () => {
  const [email, setEmail] = useState("");

  const { mutate, isError, isPending, isSuccess, reset } = useMutation({
    mutationFn: async (email: string) => {
      await publicFetch.post("/v1/auth/request-reset", { email });
    },
  });

  if (isSuccess) {
    return <ForgetPasswordEmailVerification email={email} onBack={reset} />;
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const emailValue = formData.get("email") as string;
    setEmail(emailValue);
    mutate(emailValue);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className={styles.container}>
        <div className="absolute p-1">
          <IconButton icon={<LeftArrowIcon />} type="link" to="/login" />
        </div>
        <div className="flex-row justify-center">
          <h3 className="p-1">Forgot password</h3>
        </div>
        <div
          className="flex-col gap-1 "
          style={{
            position: "absolute",
            bottom: 0,
            padding: `0 16px 32px 16px`,
          }}
        >
          <h2 className="text-center">No Worries!</h2>
          <h3 className="text-center" style={{ opacity: 0.6 }}>
            We will send a recovery link to your email
          </h3>
          <TextInput name="email" type="email" placeholder="Enter your email" />

          <StandardButton
            type="submit"
            className="w-full bg-white"
            disabled={isPending}
          >
            {isPending ? "Resetting..." : "Send link"}
          </StandardButton>
          {isError && <p>An error occurred. Please try again.</p>}
        </div>
      </div>
    </form>
  );
};
