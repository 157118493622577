import { Link } from "react-router-dom";
import ChevronRightIcon from "../icons/ChevronRightIcon";

interface AssetCardProps {
  className?: string;
  to: string;
  icon?: React.ReactElement;
  text: string;
  imgUrl?: string;
}

const AssetCard = ({
  className = "",
  to,
  icon,
  text,
  imgUrl,
}: AssetCardProps) => {
  return (
    <Link to={to} className={`flex-row border-btm-gray ${className}`}>
      <div className="flex-row items-center justify-between">
        {icon && <div className="m-1">{icon}</div>}
        {imgUrl && (
          <div className="">
            <img src={imgUrl} alt="" style={{ width: 46, marginRight: 10 }} />
          </div>
        )}
        <div className="pl-4">{text}</div>
      </div>
      <div className="grow bg-background-blue flex-row justify-end items-center pr-1">
        <ChevronRightIcon />
      </div>
    </Link>
  );
};

export default AssetCard;
