import { IconX } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import noCreditsImage from "../../assets/no-credits.png";
import styles from "./InsufficientCreditsPage.module.css";
import BlockButton from "../../components/buttons/BlockButton";
import { useQuery } from "@tanstack/react-query";
import { useFetch } from "../../context/FetchContext";
import { Me } from "../../types";

export const InsufficientCreditsPage = () => {
  const navigate = useNavigate();
  const { authFetch } = useFetch();
  const { data: me } = useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      const response = await authFetch.get<Me>(`v1/users/me`);
      return response.data;
    },
  });

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/" className={styles.backLink}>
          <IconX className={styles.backIcon} />
        </Link>
      </header>
      <main>
        <img
          src={noCreditsImage}
          alt="no-credits-image"
          className={styles.noCreditsImage}
        />
        <div className={styles.content}>
          <h1>Not enough credits</h1>
          <p>You've reached your monthly limit.</p>
          {me?.subscription && (
            <BlockButton
              onClick={() => navigate("/account")}
              text="Add credits"
              variant="primary"
            />
          )}
          <BlockButton
            variant="secondary"
            onClick={() => navigate("/subscriptions")}
            text="Upgrade Subscription"
          />
        </div>
      </main>
    </div>
  );
};
