import { Link, NavLink } from "react-router-dom";
import { FlowState } from "../../enums/enums";
import StandardButton from "../StandardButton";
import TextInput from "../TextInput";
import AuthPageHeader from "./AuthPageHeader";

interface LoginComponentProps {
  email: string;
  password: string;
  handleEmailChange: (email: string) => void;
  handlePasswordChange: (password: string) => void;
  handleLogin: (email: string, password: string) => void;
  handleFlowStateChange: (value: FlowState) => void;
}

const LoginComponent = ({
  email,
  handleEmailChange,
  password,
  handlePasswordChange,
  handleLogin,
  handleFlowStateChange,
}: LoginComponentProps) => {
  return (
    <div
      style={{
        padding: 16,
        display: "grid",
        justifyContent: "stretch",
        minHeight: "100svh",
      }}
    >
      <AuthPageHeader
        message="Not a member yet?"
        buttonLabel="Sign up"
        onClick={() => handleFlowStateChange(FlowState.SIGNUP)}
      />

      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin(email, password);
          }}
        >
          <div>
            <h1
              className="color-white"
              style={{
                fontSize: 24,
                textAlign: "left",
              }}
            >
              Login
            </h1>
            <p className="color-fade m-none ">Welcome Back</p>
          </div>
          <div>
            <TextInput
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              type="email"
            />
            <TextInput
              placeholder="password"
              value={password}
              onChange={handlePasswordChange}
              type="password"
            />
          </div>
          <div className="flex-col mt-1">
            <Link
              to="/forgot-password"
              style={{
                textAlign: "right",
                color: "var(--highlight)",
              }}
            >
              Forgot password
            </Link>
            <StandardButton type="submit" className="mt-2">
              Sign in
            </StandardButton>
          </div>
        </form>
      </div>
      <NavLink
        to="/privacy"
        style={{ display: "grid", placeContent: "center" }}
      >
        Privacy policy
      </NavLink>
    </div>
  );
};

export default LoginComponent;
