import { useState } from "react";
import AppShell from "../components/AppShell";
import HeaderV2 from "../components/headers/HeaderV2";
import SearchInput from "../components/SearchInput";
import AssetCard from "../components/AssetCard";
import Tabs from "../components/Tabs";
import LargeCard from "../components/LargeCard";

const collectionsDummy = [
  {
    name: "Magical Beasts",
    id: "1234",
    imageUrl:
      "https://imagedelivery.net/eg6Xqa-jIrYvZBm8oCXnhg/6a6f17ad-108c-4d01-ed06-185384b03500/XLSquare",
  },
  {
    name: "Lost Worlds",
    id: "4242",
    imageUrl:
      "https://imagedelivery.net/eg6Xqa-jIrYvZBm8oCXnhg/6a6f17ad-108c-4d01-ed06-185384b03500/XLSquare",
  },
  {
    name: "The King of the Warrens",
    id: "0023",
    imageUrl:
      "https://imagedelivery.net/eg6Xqa-jIrYvZBm8oCXnhg/6a6f17ad-108c-4d01-ed06-185384b03500/XLSquare",
  },
];

enum TabOptions {
  SAVED = "Saved",
  PICKS = "Our Picks",
}

const CollectionsPage = () => {
  const [search, setSearch] = useState<string>("");
  const [activeTab, setActiveTab] = useState<TabOptions>(TabOptions.SAVED);

  const handleUpdateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleTabChange = (tabname: TabOptions) => {
    setActiveTab(tabname);
  };

  return (
    <AppShell
      header={
        <HeaderV2 title="Collections" backBtn rightButtonClick={() => {}} />
      }
    >
      <SearchInput
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleUpdateSearch(e)
        }
      />
      {/* TABS */}
      <Tabs
        tabNames={[TabOptions.SAVED, TabOptions.PICKS]}
        activeTab={activeTab}
        onClick={handleTabChange}
      />
      {/* Saved */}
      {activeTab === TabOptions.SAVED && (
        <div>
          <AssetCard
            to={`/`}
            imgUrl="https://imagedelivery.net/eg6Xqa-jIrYvZBm8oCXnhg/6a6f17ad-108c-4d01-ed06-185384b03500/XLSquare"
            text="All Custom Assets"
            className="py-1"
          />
          {collectionsDummy.map((item) => (
            <AssetCard
              key={item.id}
              to={`/${item.id}`}
              imgUrl={item.imageUrl}
              text={item.name}
              className="py-1"
            />
          ))}
        </div>
      )}

      {/* Our Picks */}
      {activeTab === TabOptions.PICKS && (
        <>
          <LargeCard
            imageURL="https://imagedelivery.net/eg6Xqa-jIrYvZBm8oCXnhg/f5c65fb0-980f-4d56-bc28-d9080b417e00/XLLandscape"
            title="Sages"
            description="Wise old people"
            link="123"
            className="mb-1"
          />
          <LargeCard
            imageURL="https://imagedelivery.net/eg6Xqa-jIrYvZBm8oCXnhg/9c19e1c6-3ee4-4ba5-af87-964224b3f200/XLLandscape"
            title="Gnomes"
            description="Jolly Small Folk of the Valleys"
            link="423"
            className="mb-1"
          />
        </>
      )}
    </AppShell>
  );
};

export default CollectionsPage;
