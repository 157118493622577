import { NavLink } from "react-router-dom";
import { FlowState } from "../../enums/enums";
import CheckItem from "../CheckItem";
import StandardButton from "../StandardButton";
import TextInput from "../TextInput";
import AuthPageHeader from "./AuthPageHeader";

interface CreateAccountComponentProps {
  email: string;
  password: string;
  confirmPassword: string;
  handleEmailChange: (value: string) => void;
  handlePasswordChange: (value: string) => void;
  handleConfirmPasswordChange: (value: string) => void;
  handleFlowStateChange: (value: FlowState) => void;
  passwordMatch: boolean;
  passwordLength: boolean;
  passwordSpecialChar: boolean;
  passwordNumber: boolean;
  handleCreateAccount: (email: string, password: string) => Promise<void>;
}

const CreateAccountComponent = ({
  email,
  password,
  confirmPassword,
  handleEmailChange,
  handlePasswordChange,
  handleConfirmPasswordChange,
  handleFlowStateChange,
  passwordMatch,
  passwordLength,
  passwordSpecialChar,
  passwordNumber,
  handleCreateAccount,
}: CreateAccountComponentProps) => {
  const validated =
    passwordMatch && passwordLength && passwordSpecialChar && passwordNumber;

  return (
    <div
      style={{
        padding: 16,
        display: "grid",
        minHeight: "100svh",
      }}
    >
      <AuthPageHeader
        message="Already a member?"
        buttonLabel="Sign In"
        onClick={() => handleFlowStateChange(FlowState.LOGIN)}
      />
      <div>
        <h1 style={{ textAlign: "left" }}>Create an account</h1>
        <div className="color-fade">Craft. Create. Captivate</div>
        <div>
          <TextInput
            placeholder="email"
            value={email}
            onChange={handleEmailChange}
            type="email"
          />
          <TextInput
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            type="password"
          />
        </div>
        <div
          id="sign-up-screen-input-confirmation"
          className=" flex-col items-start color-fade"
        >
          <div style={{ marginTop: 16 }}>Password must contain</div>
          <ul className="color-fade mt-1">
            <CheckItem name="8 or more characters" checked={passwordLength} />
            <CheckItem
              name="1 or more special characters"
              checked={passwordSpecialChar}
            />
            <CheckItem name="1 or more numbers" checked={passwordNumber} />
            <CheckItem name="Passwords match" checked={passwordMatch} />
          </ul>
        </div>
        <div className="flex-col" id="sign-up-screen-confirm-container">
          <TextInput
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            type="password"
          />
          <StandardButton
            disabled={!validated}
            style={{ marginTop: 24 }}
            onClick={async () => {
              await handleCreateAccount(email, password);
              handleFlowStateChange(FlowState.EMAIL);
            }}
          >
            Create Account
          </StandardButton>
        </div>
      </div>
      <NavLink to="/privacy" className="flex-row justify-center mb-2">
        Privacy policy
      </NavLink>
    </div>
  );
};

export default CreateAccountComponent;
