import LoginComponent from "../../components/authflow/LoginComponent";
import CreateAccountComponent from "../../components/authflow/CreateAccountComponent";
import CheckYourEmailComponent from "../../components/CheckYourEmailComponent/CheckYourEmailComponent";
import StartComponent from "../../components/authflow/StartComponent";
import { FlowState } from "../../enums/enums";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const AuthPage = () => {
  const { authState } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [flowState, setFlowState] = useState<FlowState>(FlowState.START);

  //PW AUTH STATE
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordSpecialChar, setPasswordSpecialChar] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);

  const handleEmailChange = (email: string) => {
    setEmail(email);
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
  };

  const handlePasswordConfirmationChange = (password: string) => {
    setPasswordConfirmation(password);
  };

  // const handleCreateAccount = () => {
  //   console.log("Creating account");
  // };

  // Check passwords match conditions
  useEffect(() => {
    // Ensure pw and confirmation match
    if (password === "") {
      setPasswordMatch(false);
    } else if (password === passwordConfirmation) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }

    // Ensure at least 8 characters
    if (password.length >= 8) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }

    // Ensure at least one special character
    if (password.match(/[!@#$%^&*()_+=\-{};:'"<>,./?]/)) {
      setPasswordSpecialChar(true);
    } else {
      setPasswordSpecialChar(false);
    }

    // Ensure at least one number
    if (password.match(/.*[0-9].*/)) {
      setPasswordNumber(true);
    } else {
      setPasswordNumber(false);
    }
  }, [password, passwordConfirmation]);

  const { login, signup, oauthSignup } = useAuth();

  const handleFlowStateChange = (state: FlowState) => {
    setFlowState(state);
  };

  if (authState.status === "success") {
    return <Navigate to={"/"} />;
  }

  if (authState.status === "error") {
    if (flowState === FlowState.START) {
      return (
        <StartComponent
          handleOAuthSignup={oauthSignup}
          handleFlowStateChange={handleFlowStateChange}
        />
      );
    }

    if (flowState === FlowState.LOGIN) {
      return (
        <LoginComponent
          email={email}
          handleEmailChange={handleEmailChange}
          password={password}
          handlePasswordChange={handlePasswordChange}
          handleLogin={login}
          handleFlowStateChange={handleFlowStateChange}
        />
      );
    }

    if (flowState === FlowState.SIGNUP) {
      return (
        <CreateAccountComponent
          email={email}
          password={password}
          confirmPassword={passwordConfirmation}
          handleEmailChange={handleEmailChange}
          handlePasswordChange={handlePasswordChange}
          handleConfirmPasswordChange={handlePasswordConfirmationChange}
          handleFlowStateChange={handleFlowStateChange}
          passwordMatch={passwordMatch}
          passwordLength={passwordLength}
          passwordSpecialChar={passwordSpecialChar}
          passwordNumber={passwordNumber}
          handleCreateAccount={signup}
        />
      );
    }

    if (flowState === FlowState.EMAIL) {
      return (
        <CheckYourEmailComponent
          email={email}
          password={password}
          handleFlowStateChange={handleFlowStateChange}
          handleCreateAccount={signup}
        />
      );
    }

    return <div>Flow State Error</div>;
  }
};

export default AuthPage;
