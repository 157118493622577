import { Link, useParams } from "react-router-dom";
import { useFetch } from "../../../context/FetchContext";
import { useQuery } from "@tanstack/react-query";
import styles from "./Campaign.module.css";
import { IconX } from "@tabler/icons-react";
import Modal from "../../../components/modals/Modal";
import { Info } from "./components/Info";
import { useState } from "react";

export const Campaign = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { apiClient } = useFetch();
  const { id } = useParams();
  const { data: campaign } = useQuery({
    queryKey: ["campaign", id],
    queryFn: async () => {
      const res = await apiClient.api.v1.campaigns[":id"].$get({
        param: {
          id: id!,
        },
      });

      return await res.json();
    },
    enabled: !!id,
  });

  return (
    <div className={styles.campaign}>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          <Link to="/campaigns" className={styles.closeBtn}>
            <IconX color="black" />
          </Link>
        </div>
      </header>
      <main className={styles.main}>
        <div>
          <img src={campaign?.images?.[0]?.url} alt="" />
        </div>
        <ul className={styles.campaignInfo}>
          <li>
            <h2 className={styles.campaignInfoTitle}>
              4 <span>hrs</span>
            </h2>
            <p>
              {campaign?.type
                .replace("_", " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </p>
          </li>

          <li>
            <h2 className={styles.campaignInfoTitle}>
              2-{campaign?.numberOfPlayers}
            </h2>
            <p>Players</p>
          </li>
          <li>
            <h2 className={styles.campaignInfoTitle}>
              {campaign?.seasons?.[0]?._count?.episodes || 3}
            </h2>
            <p>Episodes</p>
          </li>
        </ul>
        <div className={styles.campaignDetails}>
          <div>
            <p>Setting</p>
            <p>{campaign?.setting}</p>
          </div>
          <div>
            <p>Premise</p>
            <p>{campaign?.premise}</p>
          </div>
        </div>
      </main>
      <footer className={styles.footer}>
        <button
          onClick={() => setShowInfoModal(true)}
          className={styles.startBtn}
        >
          Reveal GM Info
        </button>
        <Modal
          fullHeight
          active={showInfoModal}
          closeModal={() => setShowInfoModal(false)}
          title="Info"
        >
          <Info campaignId={id!} />
        </Modal>
      </footer>
    </div>
  );
};
