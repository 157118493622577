import { FlowState } from "../../enums/enums";

interface AlreadyMemberProps {
  handleFlowStateChange: (value: FlowState) => void;
}

const AlreadyMember = ({ handleFlowStateChange }: AlreadyMemberProps) => {
  return (
    <div
      className="flex-col items-center justify-center color-fade"
      style={{ marginTop: 32 }}
    >
      Already a member?
      <button
        className="color-highlight"
        style={{ marginTop: 2, background: "transparent" }}
        onClick={() => handleFlowStateChange(FlowState.LOGIN)}
      >
        Sign in
      </button>
    </div>
  );
};

export default AlreadyMember;
