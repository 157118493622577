import { useState } from "react";
import StandardButton from "../../components/StandardButton";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useFetch } from "../../context/FetchContext";
import styles from "./FeedbackPage.module.css";
import { IconArrowLeft } from "@tabler/icons-react";

const FeedbackPage = () => {
  const [submitted, setSubmitted] = useState(false);

  const { authFetch } = useFetch();

  const { mutate: postFeedback, isPending } = useMutation({
    mutationFn: async (feedback: FormDataEntryValue | null) => {
      await authFetch.post("/v1/feedback", {
        feedback,
      });
    },
    onSuccess: () => {
      setSubmitted(true);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const feedback = formData.get("feedback");

    postFeedback(feedback);
  };

  return (
    <div>
      <header className={styles.header}>
        <Link to="/account" className={styles.closeButton}>
          <IconArrowLeft />
        </Link>
        <h3>Feedback</h3>
      </header>
      <main className={styles.content}>
        <p>
          Thank you for using RPG Playbook. We appreciate all your feedback and
          would love to hear more about your experience with the app.
        </p>
        {submitted ? (
          <div>
            <div className="my-3">Thank you for your feedback!</div>
            <Link to="/" className="color-highlight">
              Return to App
            </Link>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <textarea
              className="w-full mt-2"
              style={{
                height: 400,
                resize: "none",
                backgroundColor: "transparent",
                color: "white",
                padding: 16,
              }}
              name="feedback"
              id="feedback-text"
            />

            <StandardButton disabled={isPending} className="w-full mt-2">
              Submit
            </StandardButton>
          </form>
        )}
      </main>
    </div>
  );
};

export default FeedbackPage;
