import { Link } from "react-router-dom";
import styles from "../Campaigns.module.css";
import { IconChevronRight } from "@tabler/icons-react";

export const CampaignCard = (props: {
  image: string;
  id: number;
  name: string;
}) => {
  return (
    <Link to={`/campaigns/${props.id}`}>
      <div className={styles.campaignImageContainer}>
        <img src={props.image} alt={props.name} />
        <div className={styles.campaignImageOverlay}>
          <div className={styles.campaignImageOverlayContent}>
            <p>View Campaign</p>
            <IconChevronRight />
          </div>
        </div>
      </div>
    </Link>
  );
};
