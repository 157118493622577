import privacyImage from "../assets/privacy.png";
import IconButton from "../components/IconButton";
import InfoDropdownCard from "../components/InfoDropDownCard";
import LeftArrowIcon from "../icons/LeftArrowIcon";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <header className="flex-row relative justify-center items-center w-full absolute">
        <IconButton
          icon={<LeftArrowIcon />}
          type="link"
          to="/"
          className="absolute"
          style={{
            background: "rgb(20, 20, 20, 0.6)",
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 20,
            marginRight: 16,
            top: 10,
            left: 10,
          }}
        />
        <h1 className="text-lg" style={{ marginTop: 14 }}>
          Privacy Policy
        </h1>
      </header>
      <div className="w-full">
        <img
          className="w-full"
          src={privacyImage}
          alt="bramble reading documents"
        />
      </div>
      <div className="m-1">
        <InfoDropdownCard
          title="Introduction"
          text={["At RPG Playbook we take your privacy seriously..."]}
        />
        <InfoDropdownCard
          title="What we do with your data"
          text={[
            "We will always respect your data and handle it in accodance with the law...",
          ]}
        />
        <InfoDropdownCard
          title="Right to Remove"
          text={["You can ask to have your data removed..."]}
        />
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
