import loadingGif from "../assets/fireball.gif";
import "../css/custom/loading.css";

const Loading = () => {
  return (
    <div
      className="laoding flex-col"
      style={{
        margin: "auto",
        maxHeight: "100%",
        alignItems: "center",
      }}
    >
      <img src={loadingGif} alt="" className="fade-in-animation" width={400} />
      <p>Creating your asset...</p>
    </div>
  );
};

export default Loading;
