import CircleTickIcon from "../icons/CircleTickIcon";
import RedCross from "../icons/RedCross";

interface CheckItemProps {
  checked: boolean;
  name: string;
}

const CheckItem = ({ name, checked }: CheckItemProps) => {
  return (
    <li className="flex-row items-center">
      {checked ? <CircleTickIcon color={"#2be6da"} /> : <RedCross />}
      <div style={{ marginLeft: 10, opacity: 0.6 }}>{name}</div>
    </li>
  );
};

export default CheckItem;
