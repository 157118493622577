export enum FlowState {
  START = "START",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  EMAIL = "EMAIL",
}

export enum TabNames {
  ASSETS = "Assets",
  COLLECTIONS = "Collections",
  NEWS = "News",
}

export enum AssetTypes {
  CHARACTER = "character",
  CREATURE = "creature",
  ITEM = "item",
  LOCATION = "location",
}

export enum Themes {
  FANTASY = "fantasy",
  SCIFI = "scifi",
  HORROR = "horror",
}
