import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import getItems from "../../assetData/itemData";
import AppShell from "../../components/AppShell";
import Loading from "../../components/Loading";
import RadioButtons from "../../components/RadioButtons";
import StandardButton from "../../components/StandardButton";
import TextInput from "../../components/TextInput";
import { useFetch } from "../../context/FetchContext";
import { Magic } from "../../enums";
import ArrowShuffleIcon from "../../icons/ArrowsShuffleIcon";
import CreateHeader from "../../components/headers/CreateHeader";
import CreditsNeeded from "../../components/CreditsNeeded";
import { ErrorPage } from "../ErrorPage/ErrorPage";

const getRandomMagic = () => {
  const magic = [Magic.Mundane, Magic.Magic, Magic.Cursed];
  return magic[Math.floor(Math.random() * magic.length)];
};

interface CreateCreatureProps {}

const CreateItem = ({}: CreateCreatureProps) => {
  const [credits, setCreadits] = useState(0);

  const location = useLocation();

  useEffect(() => {
    authFetch("/v1/users/me").then((results) => {
      setCreadits(results.data.credits);
    });
  }, []);

  const { themeId } = useParams();
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [magic, setMagic] = useState(getRandomMagic());

  const { authFetch } = useFetch();
  const navigate = useNavigate();

  const getRandomItem = () => {
    const items = getItems(location.pathname);

    handleTypeChange(items[Math.floor(Math.random() * items.length)]);
  };

  const handleTypeChange = (value: string) => {
    setType(value);
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleMagicChange = (value: Magic) => {
    setMagic(value);
  };

  const {
    mutate: handleCreateCreature,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: async () => {
      return await authFetch.post(`v1/items`, {
        theme: themeId,
        type: type,
        name: name,
        magic: magic,
      });
    },
    onSuccess: (result) => {
      navigate(`/${themeId}/asset/item/${result.data.id}`);
    },
  });

  if (isError) {
    return <ErrorPage handleOnClick={() => reset()} />;
  }
  if (!isPending) {
    return (
      <AppShell header={<CreateHeader title="New Item" subtitle={themeId} />}>
        <div className="flex-col grow">
          <div id="character-maker-form" className="grow flex flex-col ">
            <TextInput
              style={{ marginBottom: 32 }}
              label="Type"
              value={type}
              onChange={handleTypeChange}
              onClick={getRandomItem}
              buttonIcon={<ArrowShuffleIcon />}
              placeholder="Sword, Shield, etc."
            />
            <TextInput
              style={{ marginBottom: 32 }}
              label="Name"
              value={name}
              onChange={handleNameChange}
              buttonIcon={<ArrowShuffleIcon />}
              placeholder="Excalibur, Mjolnir, etc."
            />
            <RadioButtons
              classes="mt-6"
              options={[Magic.Mundane, Magic.Magic, Magic.Cursed]}
              title="Size"
              onClick={handleMagicChange}
              selectedValue={magic}
            />
            <div className="grow flex flex-col justify-end">
              <CreditsNeeded credits={credits} creditsNeeded={20} />
              <StandardButton
                onClick={() => {
                  handleCreateCreature();
                }}
              >
                Create an Item
              </StandardButton>
            </div>
          </div>
        </div>
      </AppShell>
    );
  } else {
    return <Loading />;
  }
};

export default CreateItem;
