import { Link, useNavigate } from "react-router-dom";
import styles from "./AccountPage.module.css";
import { IconChevronRight, IconCircleCheck, IconX } from "@tabler/icons-react";
import CreditStarIcon from "../../icons/CreditStarIcon";
import { useAuth } from "../../context/AuthContext";
import { useFetch } from "../../context/FetchContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import BlockButton from "../../components/buttons/BlockButton";

export const AccountPage = () => {
  const { logout } = useAuth();
  const { authFetch, apiClient } = useFetch();
  const navigate = useNavigate();

  const { data: me } = useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      const response = await apiClient.api.v1.users.me.$get();

      const data = await response.json();

      return data;
    },
  });

  const { data: products } = useQuery({
    queryKey: ["products", "purchase"],
    queryFn: async () => {
      const response = await apiClient.api.v1.products.$get();

      const data = await response.json();

      return data;
    },
  });

  const { mutate: createTopUpSession, isPending } = useMutation({
    mutationFn: async (stripePlanId: string) => {
      const response = await authFetch.post(
        "/v1/billing/create-topup-session",
        {
          planId: stripePlanId,
          successUrl: `${window.location.origin}/account?success=true`,
          cancelUrl: `${window.location.origin}/account`,
        }
      );

      return response.data;
    },
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });

  const searchParams = new URLSearchParams(window.location.search);
  const success = searchParams.get("success") === "true";

  const purchaseProducts = products?.filter(
    (product) => (product.metadata as any)?.type === "purchase"
  );

  if (success) {
    return (
      <div className={styles.successContainer}>
        <div className={styles.successMessage}>
          <IconCircleCheck size={32} color="green" />
          <h2>Credits purchased successfully!</h2>
          <BlockButton
            text="Back to home"
            variant="secondary"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.accountPage}>
      <div className={styles.content}>
        <header className={styles.header}>
          <div></div>
          <div className={styles.accountInfo}>
            <h3>Account</h3>
            <p style={{ margin: 0, fontSize: "14px", opacity: 0.7 }}>
              {me?.email}
            </p>
          </div>
          <Link to="/" className={styles.closeButton}>
            <IconX />
          </Link>
        </header>

        <div className={styles.accountDetails}>
          <Link to="/subscriptions" className={styles.detailItem}>
            <span>Subscription</span>
            <span>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {me?.subscription?.price?.product?.name}
                <IconChevronRight />
              </div>
            </span>
          </Link>

          <div className={styles.detailItem}>
            <span>Remaining Credits</span>
            <div className={styles.credits}>
              <span className={styles.starIcon}>
                <CreditStarIcon />
              </span>
              <span>{me?.subscription?.credits}</span>
            </div>
          </div>

          <div className={styles.moreCredits}>
            <p>Need more credits?</p>
            <p style={{ fontSize: "14px", opacity: 0.6 }}>
              Credits expire in 30 days from date of purchase
            </p>
            <div className={styles.buyCreditsContainer}>
              {purchaseProducts &&
                purchaseProducts.map((product) => (
                  <button
                    disabled={isPending}
                    key={product.id}
                    className={styles.buyCreditsButton}
                    onClick={() => createTopUpSession(product.prices[0].id)}
                  >
                    {product.name} £{product?.prices?.[0]?.unitAmount / 100}
                  </button>
                ))}
            </div>
          </div>

          <Link to="/feedback" className={styles.detailItem}>
            <span>Leave Feedback</span>
            <span>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <IconChevronRight />
              </div>
            </span>
          </Link>
          <Link to="/faqs" className={styles.detailItem}>
            <span>FAQs</span>
            <span>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <IconChevronRight />
              </div>
            </span>
          </Link>

          {/* <div className={styles.detailItem}>
            <span>Delete account</span>
            <Link to="/delete-account">
              <IconChevronRight />
            </Link>
          </div> */}
        </div>
      </div>

      <BlockButton
        text="Sign out"
        variant="secondary"
        onClick={logout}
        className={styles.signOut}
      />
    </div>
  );
};
