import { ButtonHTMLAttributes } from "react";

const StandardButton = ({
  children,
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { style, ...rest } = props;
  return (
    <button
      style={{
        fontWeight: 600,
        backgroundColor: "white",
        color: "black",
        minHeight: "45px",
        cursor: "pointer",
        ...style,
      }}
      className={`rounded-sm text-bold ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default StandardButton;
