interface BlockButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick: () => void;
  variant: "primary" | "secondary";
  className?: string;
}

const BlockButton = ({
  text,
  onClick,
  variant,
  className,
  ...rest
}: BlockButtonProps) => {
  const styles =
    variant === "primary"
      ? {
          minHeight: 45,
          backgroundColor: "var(--white)",
          border: "1px solid white",
          borderRadius: 4,
          color: "var(--background)",
          paddingTop: 8,
          paddingBottom: 8,
          cursor: "pointer",
        }
      : {
          minHeight: 45,
          backgroundColor: "var(--background)",
          border: "1px solid white",
          borderRadius: 4,
          color: "var(--white)",
          paddingTop: 8,
          paddingBottom: 8,
          cursor: "pointer",
        };

  return (
    <button
      className={`px-1 ${className}`}
      onClick={onClick}
      style={styles}
      {...rest}
    >
      {text}
    </button>
  );
};

export default BlockButton;
