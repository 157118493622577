import { Link } from "react-router-dom";
import styles from "./Info.module.css";
import { useState } from "react";
import { useFetch } from "../../../../context/FetchContext";
import { useQuery } from "@tanstack/react-query";
import tempImage from "../../../../assets/temp-images/age-of-fear-thumbnail.png";

export const Info = ({ campaignId }: { campaignId: string }) => {
  const { apiClient } = useFetch();
  const [activeTab, setActiveTab] = useState("Scenes");

  const tabs = ["Scenes", "Assets", "GM notes"];

  const { data: campaign } = useQuery({
    queryKey: ["campaign", campaignId],
    queryFn: async () => {
      const res = await apiClient.api.v1.campaigns[":id"].$get({
        param: {
          id: campaignId!,
        },
      });
      return await res.json();
    },
    enabled: !!campaignId,
  });

  return (
    <div className={styles.episodes}>
      <div className={styles.tabContainer}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ""}`}
          >
            <button key={tab} onClick={() => setActiveTab(tab)}>
              {tab}
            </button>
          </div>
        ))}
      </div>
      <div
        className={styles.tabContent}
        onWheel={(e) => e.stopPropagation()}
        onTouchMove={(e) => e.stopPropagation()}
      >
        {activeTab === "Scenes" && (
          <div>
            {campaign?.seasons.map((season) => (
              <div key={season.id}>
                <div className={styles.episodesContainer}>
                  {season.episodes.map((episode) => (
                    <div key={episode.id} className={styles.episodeContainer}>
                      <h4>{episode.title}</h4>
                      <div className={styles.scenesContainer}>
                        {episode.scenes.map((scene) => (
                          <div key={scene.id} className={styles.sceneCard}>
                            <div className={styles.sceneImage}>
                              <img
                                src={scene?.images?.[0]?.url || tempImage}
                                alt=""
                              />
                            </div>
                            <div className={styles.sceneInfo}>
                              <p>Scene {scene.order}</p>
                              <p>{scene.title}</p>
                              <p>{scene.shortDescription}</p>
                              <Link
                                className={styles.viewBtn}
                                to={`/campaigns/${campaignId}/seasons/${season.id}/episodes/${episode.id}/scenes/${scene.id}`}
                              >
                                View
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        {activeTab === "Assets" && <div>Assets</div>}
        {activeTab === "GM notes" && <div>GM notes</div>}
      </div>
    </div>
  );
};
