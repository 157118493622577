import { useEffect, useRef } from "react";
import checkEmailVideo from "../../assets/check-email.mp4";
import { FlowState } from "../../enums/enums";
import styles from "./CheckYourEmailComponent.module.css";
import StandardButton from "../StandardButton";

interface Props {
  email: string;
  password: string;
  handleFlowStateChange: (state: FlowState) => void;
  handleCreateAccount: (email: string, password: string) => Promise<void>;
}

const CheckYourEmailComponent = ({
  email,
  password,
  handleFlowStateChange,
  handleCreateAccount,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <video
          ref={videoRef}
          autoPlay
          className={styles.backgroundVideo}
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            objectFit: "cover",
          }}
        >
          <source src={checkEmailVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className={styles.gradient} />
        <div className={styles.pageText}>
          <h1>Check your email</h1>
          <p>We sent a verification link to</p>
          <p>{email}</p>
          <StandardButton
            type="button"
            onClick={() => handleFlowStateChange(FlowState.LOGIN)}
            className={styles.button}
          >
            Go to Login
          </StandardButton>
          <p className={styles.question}>Didn't Receive it?</p>
          <button
            className={styles.emailButton}
            type="button"
            onClick={async () => {
              await handleCreateAccount(email, password);
            }}
          >
            Click to resend
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckYourEmailComponent;
