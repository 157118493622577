import RpgPlaybookIcon from "../icons/RpgPlaybookIcon";

interface AuthPageHeaderProps {
  onClick: () => void;
  message: string;
  buttonLabel: string;
}

const AuthPageHeader = ({
  onClick,
  message,
  buttonLabel,
}: AuthPageHeaderProps) => {
  return (
    <div
      className="flex-row justify-between items-start"
      style={{
        boxSizing: "border-box",
      }}
    >
      <RpgPlaybookIcon />
      <div className="flex-col items-end">
        <div
          className="color-fade"
          style={{
            textAlign: "center",
          }}
        >
          {message}
        </div>
        <button
          style={{
            background: "transparent",
            color: "var(--highlight)",
          }}
          onClick={onClick}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

export default AuthPageHeader;
