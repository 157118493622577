import { useState, useRef, useEffect } from "react";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import IconButton from "./IconButton";

interface InfoDropdownCardProps {
  title: string;
  text: string[];
}

const InfoDropdownCard = ({ title, text }: InfoDropdownCardProps) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(open ? contentRef.current.scrollHeight : 0);
    }
  }, [open]);

  const handleExpandDropdown = () => {
    setOpen(!open);
  };

  return (
    <div className="info-card border-bottom-sm-fade pb-1 pt-1">
      <div className="info-card-header flex-row">
        <IconButton
          icon={
            <ChevronRightIcon
              style={{
                transform: open ? "rotate(90deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            />
          }
          type="button"
          onClick={handleExpandDropdown}
        />
        <h2 className="ml-0 text-md">{title}</h2>
      </div>
      <div
        ref={contentRef}
        className="info-card-text-container"
        style={{
          height: `${height}px`,
          overflow: "hidden",
          transition: "height 0.3s ease-in-out",
        }}
      >
        {text.map((para, index) => (
          <div
            className="info-card-text"
            key={index}
            style={{
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
              opacity: open ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            {para}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoDropdownCard;
