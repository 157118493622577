import React, { ReactNode, useEffect, useState, useRef } from "react";
import styles from "./Modal.module.css";
import { IconX } from "@tabler/icons-react";

interface ModalProps {
  active: boolean;
  closeModal: () => void;
  children: ReactNode;
  fullHeight?: boolean;
  title?: string;
}

const Modal: React.FC<ModalProps> = ({
  active,
  closeModal,
  children,
  fullHeight = false,
  title,
}) => {
  const [startY, setStartY] = useState<number | null>(null);
  const [currentY, setCurrentY] = useState<number | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [active]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (startY === null) return;
    setCurrentY(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (startY === null || currentY === null) return;
    const diff = currentY - startY;
    if (diff > 50) {
      closeModal();
    }
    setStartY(null);
    setCurrentY(null);
  };

  const getModalStyle = () => {
    if (startY === null || currentY === null) return {};
    const diff = currentY - startY;
    if (diff < 0) return {};
    return {
      transform: `translateY(${diff}px)`,
      transition: "opacity 0.5s ease",
    };
  };

  return (
    <>
      <div
        className={`${styles.modalOverlay} ${active ? styles.active : ""}`}
        onClick={closeModal}
      />
      <div
        ref={modalRef}
        className={`${styles.modal} ${active ? styles.active : ""} ${
          fullHeight ? styles.fullHeight : ""
        }`}
        style={getModalStyle()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <button className={styles.modalClose} onClick={closeModal}>
          <IconX />
        </button>
        <div className="w-full flex-row justify-center">
          <div className={styles.modalHandle} />
        </div>
        {title && <h2 className="text-center">{title}</h2>}
        <div className={styles.modalContent}>{children}</div>
      </div>
    </>
  );
};

export default Modal;
