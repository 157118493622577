import errorGoblinImage from "../../assets/error-goblin.png";
import BlockButton from "../../components/buttons/BlockButton";
import styles from "./ErrorPage.module.css";

interface Props {
  handleOnClick: () => void;
}

export const ErrorPage = ({ handleOnClick }: Props) => {
  return (
    <div className={styles.container}>
      <div>
        <img
          className={styles.image}
          src={errorGoblinImage}
          alt="bramble the goblin tangled in electrical wires"
        />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>Oops, something went wrong there!</h1>
        <p className={styles.subtitle}>Your credits have not been charged</p>
        <div className={styles.buttonContainer}>
          <BlockButton
            onClick={handleOnClick}
            className={styles.button}
            text="Return to create"
            variant="primary"
          />
        </div>
      </div>
    </div>
  );
};
