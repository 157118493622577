import { useNavigate } from "react-router-dom";
import BurgerIcon from "../../icons/BurgerIcon";
import BlockButton from "../buttons/BlockButton";
import IconButton from "../IconButton";
import LeftArrowIcon from "../../icons/LeftArrowIcon";

interface HeaderV2Props {
  rightButtonClick: () => void;
  title?: string;
  backBtn?: boolean;
  browseButton?: boolean;
}

const HeaderV2 = ({
  rightButtonClick,
  title,
  backBtn,
  browseButton = true,
}: HeaderV2Props) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex-row justify-between items-center bg-background px-1 w-full py-1 relative dt-hide"
      style={{ position: "sticky", top: 0, zIndex: 4 }}
    >
      <div className="flex-row items-center">
        {backBtn ? (
          <IconButton icon={<LeftArrowIcon />} to="/" type="link" />
        ) : (
          <IconButton icon={<BurgerIcon />} to="/more" type="link" />
        )}

        <h1 className="ml-1" style={{ fontSize: 18 }}>
          {title}
        </h1>
      </div>
      <div className="flex-row items-center jusitfy-between gap-1">
        {browseButton && (
          <BlockButton
            variant="secondary"
            onClick={() => navigate("/browse")}
            text="Browse"
          />
        )}
        <BlockButton
          variant="primary"
          onClick={rightButtonClick}
          text="Create"
        />
      </div>
    </div>
  );
};

export default HeaderV2;
