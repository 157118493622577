import StandardButton from "../../components/StandardButton";
import { useFetch } from "../../context/FetchContext";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { Me, Product } from "../../types";
import { AxiosResponse } from "axios";
import { IconArrowLeft, IconCheck, IconStarFilled } from "@tabler/icons-react";
import styles from "./SubscriptionsPage.module.css";
import BlockButton from "../../components/buttons/BlockButton";
import { useAuth } from "../../context/AuthContext";

interface SubBoxProps {
  me?: Me;
  product: Product;
  createPortalSession: UseMutateFunction<
    AxiosResponse<any, any>,
    Error,
    void,
    unknown
  >;
  createPortalSessionIsPending: boolean;
}

const SubBox = ({
  me,
  product,
  createPortalSession,
  createPortalSessionIsPending,
}: SubBoxProps) => {
  const { authFetch } = useFetch();

  const { mutate: createCheckoutSession, isPending } = useMutation({
    mutationFn: async (stripePlanId: string) => {
      const response = await authFetch.post(
        "/v1/billing/create-checkout-session",
        {
          planId: stripePlanId,
        }
      );

      return response.data;
    },
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });

  const active = product.id === me?.subscription?.price?.product?.id;

  const price = product.prices.find((price) => price.active === true);

  return (
    <div className={styles.subscriptionBox}>
      <div className={styles.boxHeader}>
        <h2 className={styles.productName}>{product.name}</h2>
        <div className={styles.creditsContainer}>
          <IconStarFilled size={15} color={active ? "#20E5D9" : "#F8E687"} />
          <div className={styles.creditsAmount}>
            {product.metadata?.credits}
          </div>
          <div>credits</div>
        </div>
      </div>
      <div className={styles.featuresList}>
        {product?.metadata?.features?.split(",").map((feature: string) => (
          <div key={feature} className={styles.featureItem}>
            <IconCheck color={active ? "#20E5D9" : "#F8E687"} />
            <div className={styles.featureText}>{feature}</div>
          </div>
        ))}
      </div>
      <div className={styles.priceContainer}>
        <h3>
          £{price?.unitAmount ? price.unitAmount / 100 : 0}{" "}
          <span className={styles.perMonth}>Per Month</span>
        </h3>
        {active ? (
          <div className={styles.subscribedContainer}>
            <IconCheck size={24} />
            <div>Subscribed</div>
          </div>
        ) : (
          <StandardButton
            onClick={() => {
              if (me?.subscription) {
                createPortalSession();
              } else if (price) {
                createCheckoutSession(price?.id);
              }
            }}
            className={styles.subscriptionButton}
            disabled={isPending || createPortalSessionIsPending}
          >
            Upgrade
          </StandardButton>
        )}
      </div>
    </div>
  );
};

const SubscriptionsPage = () => {
  const { authFetch, apiClient } = useFetch();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const { data: me, isLoading: meIsLoading } = useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      const response = await apiClient.api.v1.users.me.$get();
      const data = await response.json();
      return data;
    },
  });

  const { data: products, isLoading: productsIsLoading } = useQuery({
    queryKey: ["products", "subscription"],
    queryFn: async () => {
      const response = await apiClient.api.v1.products.$get();
      const data = await response.json();

      const subscriptionProducts = data.filter(
        (product) => (product.metadata as any)?.type === "subscription"
      );

      return subscriptionProducts;
    },
  });

  const {
    mutate: createPortalSession,
    isPending: createPortalSessionIsPending,
  } = useMutation({
    mutationFn: async () => {
      return await authFetch.post("v1/billing/create-portal-session", {
        returnUrl: `${window.location.origin}`,
      });
    },
    onSuccess: ({ data }) => {
      window.location.href = data.url;
    },
  });

  if (meIsLoading || productsIsLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          {me?.onboarded && (
            <IconArrowLeft onClick={() => navigate("/account")} />
          )}
        </div>
        <h3 className={styles.title}>Subscribe</h3>
      </header>
      <main className={styles.productsContainer}>
        {products?.map((product) => {
          return (
            <SubBox
              key={product.id}
              createPortalSession={createPortalSession}
              createPortalSessionIsPending={createPortalSessionIsPending}
              me={me as Me}
              product={product as Product}
            />
          );
        })}

        {me?.subscription && (
          <BlockButton
            className={styles.manageSubscriptionButton}
            text="Manage Subscription"
            variant="secondary"
            disabled={createPortalSessionIsPending}
            onClick={() => createPortalSession()}
          />
        )}
        {!me?.onboarded && (
          <BlockButton
            text="Sign out"
            variant="secondary"
            onClick={logout}
            className={styles.signOut}
          />
        )}
      </main>
    </div>
  );
};

export default SubscriptionsPage;
