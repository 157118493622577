import { FlowState } from "../../enums/enums";
import GoogleIcon from "../../icons/GoogleIcon";
import StandardButton from "../StandardButton";
import RpgPlaybookIcon from "../icons/RpgPlaybookIcon";
import AlreadyMember from "./AlreadyMember";

interface StartComponentProps {
  handleFlowStateChange: (state: FlowState) => void;
  handleOAuthSignup: () => void;
}

const StartComponent = ({
  handleFlowStateChange,
  handleOAuthSignup,
}: StartComponentProps) => {
  return (
    <div
      style={{
        padding: 16,
        display: "grid",
        placeContent: "center",
        justifyContent: "stretch",
        minHeight: "100svh",
      }}
    >
      {/* <div style={{ width: "100%" }}> */}
      <div className="flex-col justify-between items-center">
        <RpgPlaybookIcon />
        <h1
          className="color-white text-center mt-1"
          style={{
            fontSize: 24,
            marginBottom: 2,
          }}
        >
          Welcome to RPG Playbook
        </h1>
        <p
          className="color-fade text-center"
          // style={{ maxWidth: 240, marginTop: 0 }}
        >
          Craft. Create. Captivate
        </p>
      </div>
      <div className="flex-col">
        <StandardButton onClick={() => handleFlowStateChange(FlowState.SIGNUP)}>
          Get Started
        </StandardButton>
        <button
          onClick={handleOAuthSignup}
          className="flex-row items-center justify-center"
          style={{
            height: 52,
            color: "var(--white)",
            background: "transparent",
            border: "1px solid var(--trans)",
            borderRadius: 4,
            marginTop: 20,
            fontSize: 16,
            cursor: "pointer",
          }}
        >
          <GoogleIcon />
          <div className="ml-1">Sign up with Google</div>
        </button>
      </div>
      <AlreadyMember handleFlowStateChange={handleFlowStateChange} />
    </div>
    // </div>
  );
};

export default StartComponent;
