import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { publicFetch } from "../utils/fetch";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  oauthSignup: () => void;
  refresh: () => Promise<{ accessToken: string | null }>;
  authState: AuthState;
}

const AuthContext = createContext<AuthContextType>({
  authState: { accessToken: null, status: "pending" },
  isAuthenticated: false,
  login: async () => {},
  signup: async () => {},
  logout: async () => {},
  oauthSignup: () => {},
  refresh: async () => {
    return { accessToken: null };
  },
});

interface AuthState {
  accessToken: string | null;
  status: "pending" | "success" | "error";
}

const initialState: AuthState = {
  accessToken: null,
  status: "pending",
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState>(() => {
    return initialState;
  });

  const [_, setUser] = useState({});

  const refresh = useCallback(async () => {
    try {
      const response = await publicFetch.get<{
        accessToken: string | null;
        userDetails: any;
      }>("/v1/auth/refresh");
      return response.data;
    } catch (error) {
      console.error("Refresh failed", error);
      throw error;
    }
  }, []);

  useEffect(() => {
    refresh()
      .then((data): void => {
        setAuthState({ status: "success", accessToken: data.accessToken });
        setUser(data.userDetails);
      })
      .catch((error) => {
        setAuthState({ status: "error", accessToken: null });
        console.error("error: ", error);
      });
  }, [refresh]);

  const login = useCallback(async (email: string, password: string) => {
    try {
      const response = await publicFetch.post(`/v1/auth/login`, {
        email,
        password,
      });

      setAuthState({
        status: "success",
        accessToken: response.data.accessToken,
      });
    } catch (error) {
      console.error("Login failed", error);
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await publicFetch.delete(`/v1/auth/logout`);
      setAuthState({ status: "error", accessToken: null });
    } catch (error) {
      console.error("Logout failed", error);
      throw error;
    }
  }, []);

  // TODO: implement signup
  const signup = useCallback(async (email: string, password: string) => {
    try {
      await publicFetch.post("v1/auth/register", { email, password });
    } catch (error) {
      console.error("Sign-up failed", error);
      throw error;
    }
  }, []);

  const oauthSignup = useCallback(() => {
    try {
      window.location.href = `/api/v1/auth/google`;
    } catch (error) {}
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authState.status === "success",
        login,
        oauthSignup,
        signup,
        logout,
        authState,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
