import { useNavigate } from "react-router-dom";
import LeftArrowIcon from "../../../icons/LeftArrowIcon";
import BurgerIcon from "../../../icons/BurgerIcon";
import IconButton from "../../IconButton";
import BlockButton from "../../buttons/BlockButton";
import "./MobileHeader.css";
import { Button } from "../../buttons/Button";

interface MobileHeaderProps {
  rightButtonClick: () => void;
  title?: string;
  backBtn?: boolean;
  browseButton?: boolean;
}

const MobileHeader = ({
  rightButtonClick,
  title,
  backBtn,
  browseButton = true,
}: MobileHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex-row justify-between items-center bg-background px-1 w-full py-1"
      style={{ position: "sticky", top: 0, zIndex: 4 }}
    >
      <div className="flex-row items-center">
        {backBtn ? (
          <IconButton icon={<LeftArrowIcon />} to="/" type="link" />
        ) : (
          <IconButton icon={<BurgerIcon />} to="/account" type="link" />
        )}

        <h1 className="ml-1" style={{ fontSize: 18 }}>
          {title}
        </h1>
      </div>
      <div className="flex-row items-center jusitfy-between gap-1">
        {browseButton && (
          <BlockButton
            variant="secondary"
            onClick={() => navigate("/browse")}
            text="Browse"
          />
        )}
        <Button variant="secondary" onClick={rightButtonClick}>
          Create
        </Button>
      </div>
    </div>
  );
};

export default MobileHeader;
