import { IconArrowLeft, IconChevronRight } from "@tabler/icons-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import faqImage from "../../assets/privacy.png";
import styles from "./FAQsPage.module.css";

const faqs = [
  {
    question: "What is RPG Playbook?",
    answer:
      "RPG Playbook is an AI-powered service that generates characters, items, locations, and creatures for role-playing games, creative writing, and world building. Each generation includes a detailed 5-paragraph backstory or description and an accompanying image.",
  },
  {
    question: "How does the credit system work?",
    answer:
      "Users pay a monthly subscription fee for a set number of credits. These credits are used to generate characters, items, locations, or creatures within the app. Credits are refreshed at the start of each billing cycle.",
  },
  {
    question: "Do unused credits carry over to the next month?",
    answer:
      "No, credits do not carry over from month to month. Any unused credits expire at the end of your billing cycle.",
  },
  {
    question: "Can I purchase additional credits?",
    answer:
      "Yes, subscribers have the option to purchase additional credit top-ups. This feature is only available to users with an active subscription.",
  },
  {
    question: "What can I create with my credits?",
    answer:
      "You can use your credits to generate characters, items, locations, or creatures. Each generation includes a detailed 5-paragraph backstory or description and an image.",
  },
  {
    question: "How detailed are the generated descriptions?",
    answer:
      "Each generated item comes with a 5-paragraph backstory or description, providing rich detail about the character, item, location, or creature you've created.",
  },
  {
    question: "How is my payment information handled?",
    answer:
      "All credit card data is securely held and processed by Stripe, a trusted third-party payment processor. We do not store your full credit card details on our servers.",
  },
  {
    question: "What user data do you store?",
    answer:
      "We store essential user data including your email address and name in our SQL database. This information is used to manage your account and provide our services.",
  },
  {
    question: "Is my data secure?",
    answer:
      "Yes, we take data security seriously. Your personal information is stored securely in our SQL database, and as mentioned, all payment information is handled by Stripe.",
  },
  {
    question: "I'm experiencing issues with the app. Who should I contact?",
    answer:
      "If you're experiencing any issues with the app, please email our support team at support@rpgplaybook.com. Our technical team will be happy to assist you.",
  },
  {
    question:
      "What happens to my credits and app access when I cancel my subscription?",
    answer:
      "When you cancel your subscription, you will still be able to use the app and your remaining credits until the end of your current billing period. At the end of this period, your subscription will end, and any unused credits will be deleted.",
  },
];

const AccordionItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.accordionItem}>
      <button
        className={styles.accordionButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        <IconChevronRight
          className={`${styles.icon} ${isOpen ? styles.iconRotated : ""}`}
          size={24}
        />
        <span className={styles.question}>{question}</span>
      </button>
      {isOpen && <div className={styles.answer}>{answer}</div>}
    </div>
  );
};

export const FAQsPage = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/account" className={styles.backLink}>
          <IconArrowLeft className={styles.backIcon} />
        </Link>
        <h3>FAQs</h3>
      </header>
      <main>
        <img src={faqImage} alt="faq-image" className={styles.faqImage} />
        <div className={styles.accordion}>
          {faqs.map((faq, index) => (
            <AccordionItem
              key={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
      </main>
    </div>
  );
};
