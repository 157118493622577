import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import getCharacterNames from "../../assetData/characterNames";
import getCharacterRoles from "../../assetData/characterRoles";
import getCharacterRaces from "../../assetData/chracterRaces";
import AppShell from "../../components/AppShell";
import Loading from "../../components/Loading";
import RadioButtons from "../../components/RadioButtons";
import StandardButton from "../../components/StandardButton";
import TextInput from "../../components/TextInput";
import { useFetch } from "../../context/FetchContext";
import { Ages, Genders } from "../../enums";
import ArrowShuffleIcon from "../../icons/ArrowsShuffleIcon";
import CreateHeader from "../../components/headers/CreateHeader";
import CreditsNeeded from "../../components/CreditsNeeded";
import { ErrorPage } from "../ErrorPage/ErrorPage";

const CreateCharacter = () => {
  const [credits, setCredits] = useState<number>(10);

  const location = useLocation();

  let rolePlaceholder = "";

  if (location.pathname.includes("fantasy"))
    rolePlaceholder = "A high wizard in the colleges of magic...";
  if (location.pathname.includes("horror"))
    rolePlaceholder = "A private detective searching for the truth...";
  if (location.pathname.includes("scifi"))
    rolePlaceholder = "A starbound rogue seeking fortune and fame...";

  const getRandomAge = () => {
    const random = Math.floor(Math.random() * 3);
    switch (random) {
      case 0:
        return Ages.Young;
      case 1:
        return Ages.MiddleAged;
      case 2:
        return Ages.Old;
      default:
        return Ages.MiddleAged;
    }
  };

  const getRandomSex = () => {
    const random = Math.floor(Math.random() * 3);
    switch (random) {
      case 0:
        return Genders.Male;
      case 1:
        return Genders.Female;
      case 2:
        return Genders.Neutral;
      default:
        return Genders.Neutral;
    }
  };

  const getRandomName = () => {
    const names = getCharacterNames(location.pathname);

    return names[Math.floor(Math.random() * names.length)];
  };

  const getRandomRace = () => {
    let races = getCharacterRaces(location.pathname);

    return races[Math.floor(Math.random() * races.length)];
  };

  const getRandomRole = () => {
    const roles = getCharacterRoles(location.pathname);

    return roles[Math.floor(Math.random() * roles.length)];
  };

  useEffect(() => {
    authFetch("/v1/users/me").then((results) => {
      setCredits(results.data.credits);
    });
  }, []);

  const navigate = useNavigate();
  const { themeId } = useParams();

  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [species, setSpecies] = useState("");
  const [sex, setSex] = useState(getRandomSex());
  const [age, setAge] = useState(getRandomAge());

  const { authFetch } = useFetch();

  const handleSpeciesChange = (value: string) => {
    setSpecies(value);
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleRoleChange = (value: string) => {
    setRole(value);
  };

  const handleGenderChange = (value: Genders) => {
    setSex(value);
  };

  const handleAgeChange = (value: Ages) => {
    setAge(value);
  };

  const {
    mutate: handleCreateCharacter,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: async () => {
      return await authFetch.post(`v1/characters`, {
        theme: themeId,
        species: species,
        name: name,
        role: role,
        gender: sex,
        age: age,
      });
    },
    onSuccess: (result) => {
      navigate(`/${themeId}/asset/character/${result.data.id}`);
    },
  });

  if (isError) {
    return <ErrorPage handleOnClick={() => reset()} />;
  }

  if (!isPending) {
    return (
      <AppShell
        header={<CreateHeader title="New Character" subtitle={themeId} />}
      >
        <div
          className="grow flex-col scrolling-content"
          style={{
            boxSizing: "border-box",
          }}
        >
          <TextInput
            style={{ marginBottom: 32 }}
            label="Role description"
            value={role}
            onChange={handleRoleChange}
            onClick={() => handleRoleChange(getRandomRole())}
            buttonIcon={<ArrowShuffleIcon />}
            placeholder={rolePlaceholder}
          />
          <TextInput
            style={{ marginBottom: 32 }}
            label="Character Name"
            value={name}
            onChange={handleNameChange}
            onClick={() => handleNameChange(getRandomName())}
            buttonIcon={<ArrowShuffleIcon />}
            classes="mt-6"
            placeholder="Casian Grey, Elara Moonshadow, Thrain Ironbeard..."
          />
          <TextInput
            style={{ marginBottom: 32 }}
            label="Character Race"
            value={species}
            onChange={handleSpeciesChange}
            onClick={() => handleSpeciesChange(getRandomRace())}
            buttonIcon={<ArrowShuffleIcon />}
            classes="mt-6"
            placeholder="Human, Elf (Wild Woods), Orc (Savage), Dustrunner..."
          />
          <RadioButtons
            options={[Genders.Male, Genders.Female, Genders.Neutral]}
            title="Sex"
            onClick={handleGenderChange}
            selectedValue={sex}
          />
          <RadioButtons
            options={[Ages.Young, Ages.MiddleAged, Ages.Old]}
            title="Age"
            onClick={handleAgeChange}
            selectedValue={age}
          />
          <div className="flex flex-col justify-end">
            <CreditsNeeded credits={credits} creditsNeeded={20} />

            <StandardButton
              onClick={() => {
                handleCreateCharacter();
              }}
              disabled={credits < 20}
            >
              Create Character
            </StandardButton>
          </div>
        </div>
      </AppShell>
    );
  } else {
    return <Loading />;
  }
};

export default CreateCharacter;
