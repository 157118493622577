import { Link } from "react-router-dom";

interface LargeCardProps {
  imageURL: string;
  title: string;
  description?: string;
  link: string;
  className?: string;
}

const LargeCard = ({
  imageURL,
  title,
  description,
  link,
  className,
}: LargeCardProps) => {
  return (
    <div className={`relative w-full rounded-md ${className}`}>
      <img
        className="w-full rounded-md"
        src={imageURL}
        alt="card cover image"
      />
      <h2 className="absolute" style={{ bottom: 100, left: 16, zIndex: 20 }}>
        {title}
      </h2>
      <div className="absolute" style={{ bottom: 80, left: 16, zIndex: 20 }}>
        {description}
      </div>
      <Link
        className="rounded-smooth absolute"
        style={{
          bottom: 16,
          left: 16,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 6,
          background: "rgba(255, 255, 255, 0.3)",
          zIndex: 20,
        }}
        to={link}
      >
        View Collection
      </Link>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
        }}
        className="linear-gradient"
      />
    </div>
  );
};

export default LargeCard;

//
