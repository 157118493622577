import styles from "./DeleteAccountPage.module.css";
import { IconArrowLeft } from "@tabler/icons-react";
import deleteAccountImage from "../../assets/delete-account-bg.png";
import StandardButton from "../../components/StandardButton";
import { Link } from "react-router-dom";

// TODO: Add delete account functionality
export const DeleteAccountPage = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/account" className={styles.backButton}>
          <IconArrowLeft />
        </Link>
        <h3 className={styles.title}>Delete account</h3>
      </header>
      <main className={styles.main}>
        <img
          src={deleteAccountImage}
          alt="Character"
          className={styles.characterImage}
        />
        <div className={styles.content}>
          <h2 className={styles.question}>Delete account?</h2>
          <p className={styles.warning}>
            You will lose any existing assets and credits linked to your account
          </p>
          <StandardButton
            className={styles.deleteButton}
            style={{ color: "rgba(205, 47, 34, 1)" }}
          >
            Delete account
          </StandardButton>
        </div>
      </main>
    </div>
  );
};
